import React from "react";
import { Typography } from "@material-ui/core";

import { Link } from "../components/link";

import Layout from "../layouts/layout";
import SEO from "../components/seo";

const ProvacyPolicyPage = () => (
  <Layout>
    <>
      <SEO title="プライバシーポリシー | 【まるっと名言集】偉人やアニメなどの名言を抜粋" />
      <Typography
        component="h1"
        style={{ margin: "12px 0", fontWeight: "bold" }}
      >
        プライバシーポリシー
      </Typography>
      <Typography component={"h2"}>個人情報の利用目的</Typography>
      <Typography component="p">
        当ブログでは、メールでのお問い合わせなどの際に、名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。
      </Typography>
      <Typography component="p">
        これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
      </Typography>
      <Typography component={"h2"}>個人情報の第三者への開示</Typography>
      <Typography component="p">
        当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
      </Typography>
      <Typography component="p">・本人のご了解がある場合</Typography>
      <Typography component="p">
        ・法令等への協力のため、開示が必要となる場合
      </Typography>
      <Typography component={"h2"}>
        個人情報の開示、訂正、追加、削除、利用停止
      </Typography>
      <Typography component="p">
        ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます
      </Typography>
      <Typography component={"h2"}>アクセス解析ツールについて</Typography>
      <Typography component="p">
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </Typography>
      <Typography component={"h2"}>免責事項</Typography>
      <Typography component="p">
        当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的ではございません。記事の内容や掲載画像等に問題がございましたら、各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。
      </Typography>
      <Typography component="p">
        当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
      </Typography>
      <Typography component="p">
        当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。
      </Typography>
      <Typography component="p">
        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
      </Typography>
      <Typography component={"h2"}>
        プライバシーポリシーの変更について
      </Typography>
      <Typography component="p">
        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
        修正された最新のプライバシーポリシーは常に本ページにて開示されます。
      </Typography>
      <Link to="/">トップページへ</Link>
    </>
  </Layout>
);

export default ProvacyPolicyPage;
